import React, { useState } from "react";
import { animated, useTransition, config } from "react-spring";
import ReactMapboxGL, { Marker } from "react-mapbox-gl";

import Triangle from "./triangle";
import { ThemeContext } from "../../../provider";
import Pin from "../../images/common/pin.svg";

const bounds = [
  [-70.368344, 42.804557],
  [-89.186424, 32.143934],
];

const offices = [
  {
    coords: [-76.8112096, 39.1859321],
    officeName: "Main Office",
    address: `6940 Columbia Gateway Drive, Suite 110\nColumbia, MD 21046`,
  }
];

const Map = ReactMapboxGL({
  accessToken: `${process.env.GATSBY_MAPBOX_TOKEN}`,
  interactive: true,
  attributionControl: false,
});

const styles = {
  backgroundColor: "#4ab0ff",
  borderRadius: "50%",
  fontFamily:
    '"Poppins", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  width: "20px",
  height: "20px",
  cursor: "pointer",
};

const LocationSection = () => {
  const [items] = useState(offices);
  const [index, setIndex] = useState(0);

  const officeTransition = useTransition(
    items[index],
    (item) => item.officeName,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0, position: "absolute" },
      delay: 750,
      config: config.slow,
    }
  );

  const handleOfficeUpdate = (index) => setIndex(index);

  return (
    <ThemeContext.Consumer>
      {(context) => (
        <section className="h-full w-screen bg-white relative overflow-y-hidden">
          <Map
            style={`${
              context.isDark
                ? `mapbox://style/mapbox/light-v10`
                : `mapbox://style/mapbox/dark-v10`
            }`}
            fitBounds={bounds}
          >
            <Marker
              coordinates={offices[0].coords}
              onClick={() => handleOfficeUpdate(0)}
            >
              {items[index].coords === offices[0].coords ? (
                <Pin width="30px" height="30px" />
              ) : (
                <div style={{ ...styles }} />
              )}
            </Marker>
          </Map>
          <div
            className="text-copy-secondary text-left w-full p-6 pb-0 md:pb-6 container mx-auto lg:hidden"
            id="office-locations"
          >
            <h2 className="text-3xl leading-snug font-bold text-left lg:text-4xl xl:text-5xl">
              Our Location
            </h2>
          </div>
          <div className="lg:hidden">
            <div className="bg-white p-6 w-full h-full lg:space-between container mx-auto">
              <h3 className="text-base md:text-lg font-medium">
                {items[index].officeName}
              </h3>
              <address className="mt-4 md:mt-8 text-base md:text-lg font-medium not-italic leading-relaxed">
                {items[index].address.split("\n").map((item) => (
                  <div key={item}>{item}</div>
                ))}
              </address>
              <address className="mt-4 md:mt-8 text-base md:text-lg font-medium not-italic leading-relaxed">
                Phone: (443) 583-4810
                <br />
                Fax: (410) 761-3642
                <br />
                <a href="mailto:hello@clarityinnovates.com">
                  hello@clarityinnovates.com
                </a>
              </address>
            </div>
          </div>
          <Triangle
            num="map"
            css={{ path: "#f2f4f9" }}
            rotate={60}
            translateX={439}
            translateY={-10}
          >
            <div className="max-w-xs office-locations mt-10 3xl:mt-0">
              <h2 className="text-3xl leading-snug font-bold text-left lg:text-4xl xl:text-5xl">
                Our Location
              </h2>
              {officeTransition.map(({ item, props, key }) => (
                <animated.div style={props} key={key}>
                  <h3 className="text-xl font-medium 3xl:text-2xl">
                    {item.officeName}
                  </h3>
                  <address className="mt-6 text-xl font-medium not-italic leading-normal 3xl:text-2xl">
                    {item.address}
                  </address>
                  <address className="mt-6 text-xl font-medium not-italic leading-normal 3xl:text-2xl">
                    Phone: (443) 583-4810
                    <br />
                    Fax: (443) 329-8899
                    <br />
                    <a href="mailto:hello@clarityinnovates.com">
                      hello@clarityinnovates.com
                    </a>
                  </address>
                </animated.div>
              ))}
            </div>
          </Triangle>
        </section>
      )}
    </ThemeContext.Consumer>
  );
};

export default LocationSection;
